import React from "react";
import imageBack from "../assets/images/home/supplier/hero-suppliers.webp";

export function BecomeSupplier() {
  return (
    <section
      className="px-4 space-y-2 text-center text-white bg-white bg-center bg-no-repeat bg-cover lg:px-20 py-14 md:py-36 h2"
      style={{
        backgroundImage: `url(${imageBack})`,
      }}
    >
      <p>
        hazte proveedor, descubre
        <span className="text-[#008EA3]"> elgestionador</span>
      </p>
      <p className="pt-6 body container m-auto max-w-[100vh]">
        Trabajamos en estrecha colaboración con las principales tiendas de
        herramientas y maquinaria para diseñar un sistema que gestione sin
        problemas el proceso de alquiler de principio a fin.
      </p>
      <div className="items-center justify-center w-full gap-6 pt-12 md:flex">
        <a href="https://elgestionador.com/" target="_blank">
          <button className="w-[80vw] md:w-60 m-auto bg-transparent button flex items-center justify-center text-dark bg-white rounded-full px-2 py-6 border-2 border-white hover:bg-transparent hover:text-white transition-all ease-in-out duration-150">
            <p>unirse</p>
          </button>
        </a>
        <a href="/proveedores">
          <button className="w-[80vw] md:w-60 mt-4 md:mt-0 m-auto bg-[#008EA3] button flex items-center justify-center text-white rounded-full px-2 py-6 border-2 border-[#008EA3] hover:bg-transparent hover:text-[#008EA3] transition-all ease-in-out duration-150">
            <p>más información</p>
          </button>
        </a>
      </div>
    </section>
  );
}

import React from "react";
import imageStep2 from "../assets/images/home/steps/steps_book.webp";
import imageStep3 from "../assets/images/home/steps/steps_enjoy.webp";
import imageStep1 from "../assets/images/home/steps/steps_search.webp";

export function SectionSteps() {
  return (
    <section className="bg-white">
      <div className="container px-4 m-auto space-y-2 text-center text-black bg-white lg:px-20 py-14 md:py-24 h2">
        <h2>
          alquila de la forma<span className="text-primary"> más sencilla</span>
        </h2>
        <div className="gap-12 pt-12 lg:flex">
          <div className="flex-1 mb-14 md:mb-0">
            <img
              src={imageStep1}
              className="object-contain w-full h-full lg:h-96"
              alt="Encuentra la maquinaria y herramientas online"
            />
            <h3 className="text-black pt-9 h2">
              1.<span className="text-primary">busca</span>
            </h3>
            <p className="px-0 pt-2 font-bold md:px-4 body text-dark">
              Encuentra la maquinaria y herramientas que necesitas.
            </p>
          </div>
          <div className="flex-1 mb-14 md:mb-0">
            <img
              src={imageStep2}
              className="object-contain h-full lg:h-96 w-fit"
              alt="Reserva maquinaria y herramientas online"
            />
            <h3 className="text-black pt-9 h2">
              2.<span className="text-primary">reserva</span>
            </h3>
            <p className="px-0 pt-2 font-bold md:px-4 body text-dark">
              Elige el producto, verifica las fechas y haz tu reserva.
            </p>
          </div>
          <div className="flex-1 mb-14 md:mb-0">
            <img
              src={imageStep3}
              className="object-contain w-full h-full lg:h-96"
              alt="Alquilar herramientas y maquinaria online"
            />
            <h3 className="text-black pt-9 h2">
              3.<span className="text-primary">disfruta</span>
            </h3>
            <p className="px-0 pt-2 font-bold md:px-4 body text-dark">
              La mejor experiencia con un servicio de calidad.
            </p>
          </div>
        </div>
        <div className="flex justify-center pt-9">
          <a href="/como-funcionamos">
            <button className="flex items-center justify-center py-6 text-black transition-all duration-150 ease-in-out bg-transparent border-2 rounded-full button px-14 border-primary hover:bg-primary hover:text-white">
              <p>cómo funciona</p>
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

import React, { useState } from "react";
import imageProduct from "../assets/images/image-product.png";
import imageHeart from "../assets/images/heart.svg";
import imageHeartFilled from "../assets/images/heart-filled.svg";
import defaultImage from "../assets/images/NOIMAGE.jpg";
import InformationIcon from "./ui/InformationIcon";
import Label from "./ui/Label";
import StarRating from "./ui/StarRating";

const informationItems = [
  "Montaje no incluido",
  "Transporte no incluido",
  "Consumibles no incluídos",
];
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;

const ProductCard = ({
  key,
  product,
  isFullWidth,
  isAuthenticated,
  isFavorite,
  useH2 = false,
}) => {
  const divClass = isFullWidth
    ? "w-full"
    : "3xl:w-[calc(25%-1rem)] xl:w-[calc(33.33%-1rem)] w-full";

  const HeadingTag = useH2 ? "h2" : "h3";

  const [favoriteStatus, setFavoriteStatus] = useState(isFavorite);
  const [loadingFavorite, setLoadingFavorite] = useState(false);

  const toggleFavorite = async () => {
    if (loadingFavorite) return;

    setLoadingFavorite(true);
    const method = favoriteStatus ? "DELETE" : "POST";
    const url = `${API_BASE_URL}/user/products/favorites/${product.id}`;
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(
          `Failed to modify favorite. Status: ${response.status}`
        );
      }
      setFavoriteStatus(!favoriteStatus);
    } catch (error) {
      console.error("Error managing favorite:", error);
    } finally {
      setLoadingFavorite(false);
    }
  };

  const shouldLink = product.products_offers_published?.length > 0;
  const Container = shouldLink ? "a" : "div";

  return (
    <Container
      href={shouldLink ? `/alquiler-de/${product.slug}` : undefined}
      className={`contents ${shouldLink ? "cursor-pointer" : ""}`}
      key={key}
    >
      <div
        key={key}
        className={` ${divClass} relative flex shrink-0 grow-0 flex-col rounded-2xl bg-white p-5`}
      >
        <div className="absolute z-1 flex flex-col gap-1 left-[12px] top-[12px]">
          {product.tags?.map((tag, index) => {
            const parts = tag.split("_label_");
            return parts.length > 1 ? (
              <Label key={index} text={parts[1]} borderColor="#58A291" />
            ) : null;
          })}

          {product.attributes &&
            product.attributes["9"] &&
            product.attributes["9"].pivot.value === "eléctrica" && (
              <Label text="ECO" color="#F19540" backgroundColor="#414042" />
            )}
        </div>
        {isAuthenticated ? (
          <div className="absolute right-4 top-4" onClick={toggleFavorite}>
            <img
              src={favoriteStatus ? imageHeartFilled : imageHeart}
              alt="heart icon"
              className={`text-primary ${
                loadingFavorite ? "opacity-50" : "opacity-100"
              }`}
            />
          </div>
        ) : (
          ""
        )}
        <div className="flex flex-col flex-1 h-full">
          <div className="flex items-center justify-center h-52">
            {product.image && product.image.path ? (
              <img
                src={IMAGE_BASE_URL + product.image.path}
                alt=""
                className="h-full"
              />
            ) : (
              <img src={defaultImage} alt="" data-test className="h-full" />
            )}
          </div>
          <div className="flex flex-col text-left">
            <p className="uppercase text-[#B8B8B8] text-sm">
              {product.use_type === "machine" ? "Maquinaria" : ""}
              {product.use_type === "tool" ? "Herramienta" : ""}
              {product.use_type === "auxiliaries" ? "Medios Auxiliares" : ""}
            </p>
            <HeadingTag className="text-lg font-bold truncate">
              {product.short_name}
            </HeadingTag>
            <div className="h-6 mt-2 truncate body">{product.description}</div>
          </div>
          <div className="flex flex-row justify-between w-full  mt-5">
            {product.products_offers_published?.length > 0 ? (
              <span className="text-[15px] text-primary font-bold block">
                desde: {Number(product.products_offers_published[0].days) * 1}€
              </span>
            ) : (
              <span className="text-[12px] text-[#B8B8B8]">No hay ofertas</span>
            )}
            <span className="text-[15px] text-primary font-bold block">
              cp: {product.products_offers_published[0].junior.zip}
            </span>
          </div>
          <div className="relative flex items-end justify-between">
            <div className="flex flex-col text-left">
              <span className="text-[12px] text-[#B8B8B8]">
                IVA no incluido
              </span>
              <div className="flex items-center gap-1 group">
                <span className="text-[12px] text-[#B8B8B8]">
                  Información sobre el precio
                </span>
                <span className="icon hover:cursor-pointer">
                  <InformationIcon />
                </span>
                <div className="font-bold absolute left-[10%] bottom-6 hidden group-hover:flex flex-col gap-2 rounded-[8px] bg-[#58A291] shadow-[0_4px_4px_rgba(0,0,0,0.10)] p-[15px]">
                  {informationItems.map((item) => (
                    <div className="flex items-center gap-2" key={item}>
                      <InformationIcon color="#414042" />
                      <span className="text-[15px] text-[#F4F4F4]">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col text-right items-end gap-[7px]">
              {product.products_offers_published[0].rating > 0 && (
                <StarRating
                  rating={product.products_offers_published[0].rating}
                />
              )}
              {product.products_offers_published[0].reviews > 0 && (
                <span className="text-[12px] text-[#B8B8B8]">
                  {product.products_offers_published[0].reviews} reseñas
                </span>
              )}
            </div>
          </div>
        </div>
        {product.products_offers_published?.length > 0 ? (
          <button
            href={`/alquiler/${product.slug}`}
            className="mt-2 w-full py-[20px] bg-[#58A291] text-white rounded-full text-sm font-normal text-center"
          >
            alquilar
          </button>
        ) : (
          <button
            className="mt-2 w-full py-[20px] bg-gray text-white rounded-full text-sm font-normal text-center"
            disabled
          >
            Alquilar
          </button>
        )}
      </div>
    </Container>
  );
};

export default ProductCard;

import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AccordionCustomIcon } from "../components/product-components/Accordion";
import { TransparentTabs } from "../components/product-components/TabsAtributtes";
import { Modal } from "../components/product-components/Modal";
import ShoppingCart from "../components/product-components/AddToCart";
import CarrouselSection from "../components/CarrouselSection";
import ProductCard from "../components/ProductCard";
import ModalInfo from "../components/Modal";
import ProductImages from "../components/product-components/ProductGallery";
import ProductDetails from "../components/product-components/ProductDetails";
import { ReactComponent as ShareIcon } from "../assets/icons/ShareIcon.svg";
import { ReactComponent as Chat } from "../assets/icons/Chat.svg";
import BestSellerProducts from "../components/product-components/BestSellerProducts";
import CategoryAndSeoSection from "../components/product-components/CategoryAndSeoSection";
import {
  fetchProducts,
  fetchProductDetails,
  fetchHolidays,
  fetchShippingInfo,
  fetchUserFavorites,
  toggleFavoriteProduct,
} from "../services/apiService";
import { useError } from "../helpers/ErrorContext";
import { useAuth } from "../hooks/AuthProvider";
import imageHeart from "../assets/images/heart.svg";
import imageHeartFilled from "../assets/images/heart-filled.svg";
import StarRating from "../components/ui/StarRating";
import ProductSchema from "../components/product-components/ProductSchema";

const PDP = () => {
  const { setError } = useError();
  const { token, isAuthenticated } = useAuth();
  const { id } = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [holidays, setHolidays] = useState({});
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showMoreOffers, setShowMoreOffers] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(1);
  const [dates, setDates] = useState({ from_date: null, to_date: null });
  const [sessionID, setSessionID] = useState(
    localStorage.getItem("session_id") || ""
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [zip, setZip] = useState("");
  const [storePickup, setStorePickup] = useState(false);
  const [storeDelivery, setStoreDelivery] = useState(false);
  const [amount, setAmount] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantityLimitMessage, setQuantityLimitMessage] = useState("");
  const [minPeriodString, setMinPeriodString] = useState("No hay disponible");
  const [resetDatePicker, setResetDatePicker] = useState(false);
  const [selectedZip, setSelectedZip] = useState("");
  const [selectedTown, setSelectedTown] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({});
  const [favorites, setFavorites] = useState([]);
  const [favoriteStatus, setFavoriteStatus] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [selectedShippingOptions, setSelectedShippingOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [shippingData, setShippingData] = useState({});
  const [timerId, setTimerId] = useState(null);
  const [rentButton, setRentButton] = useState(false);
  const [addToCartActive, setAddToCartActive] = useState(false);

  const opciones = [
    "Recogida en almacén",
    "Devolución en almacén",
    "Envío a domicilio",
    "Recogida en domicilio",
  ];

  const options = [
    ["Recogida en almacén", "Envío a domicilio"],
    ["Devolución en almacén", "Recogida en domicilio"],
  ];

  const products = [];
  const [productsApi, setProductsApi] = useState([]);
  const arrayFilters = {
    filters: { popular: true, with_offers: true },
    with: ["image", "products_offers_published.junior"],
  };

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const responseData = await fetchProducts(arrayFilters);
        setProductsApi(Object.values(responseData.data));
      } catch (error) {
        console.error("Error:", error);
      }
    };
    loadProducts();
  }, [setError]);

  const slides = productsApi.map((product, index) => (
    <ProductCard key={product.id} product={product} isFullWidth={true} />
  ));

  const urlParams = [
    ["with[0]", "images"],
    ["with[1]", "products_offers_published.junior"],
    ["with[2]", "category.tags_filters"],
  ];

  const paramString = urlParams
    .map(([param, value]) => `${param}=${value}`)
    .join("&");

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const responseData = await fetchProductDetails(id, paramString);
        setProduct(responseData);
      } catch (error) {
        console.error("Error en la solicitud:", error);
      } finally {
        setLoading(false);
      }
    };

    loadProduct();
  }, [id, paramString, setError]);

  const loadHolidays = async (offerId) => {
    try {
      const today = new Date();
      const formattedStartDate = today.toISOString().split("T")[0];
      const twoYearsFromNow = new Date(today);
      twoYearsFromNow.setFullYear(today.getFullYear() + 2);
      twoYearsFromNow.setMonth(today.getMonth() + 6);
      const formattedEndDate = twoYearsFromNow.toISOString().split("T")[0];
      const responseData = await fetchHolidays(
        offerId,
        setError,
        formattedStartDate,
        formattedEndDate
      );
      setHolidays({
        holidays: responseData.holidays || [],
        bookings: Array.isArray(responseData.bookings)
          ? responseData.bookings
          : Object.values(responseData.bookings || {}),
      });
    } catch (error) {
      setError(error);
      console.error(
        "Error en la solicitud de días festivos y reservas:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedOptions[0] && selectedOptions[1]) {
      setShippingOptions(false);
    }
  }, [selectedOptions]);

  const formatDisabledDates = (data) => {
    const dates = [];

    if (data.holidays && Array.isArray(data.holidays)) {
      data.holidays.forEach((holiday) => {
        dates.push({ startDate: holiday.from_date, endDate: holiday.to_date });
      });
    }

    if (data.bookings && Array.isArray(data.bookings)) {
      data.bookings.forEach((booking) => {
        dates.push({ startDate: booking.from_date, endDate: booking.to_date });
      });
    }

    return dates;
  };

  const handleSelectOffer = (offer) => {
    setSelectedOffer(offer);
    setMaxQuantity(parseInt(offer.quantity, 10));
    setMinPeriodString(getPeriodString(offer.min_period, offer.period_type));
    setShowMoreOffers(false);
    setResetDatePicker(true);
    setTotalDays(0);
    setSelectedZip(offer.junior ? offer.junior.zip : "");
    setSelectedTown(offer.junior ? offer.junior.town : "");
    setSelectedLocation(offer.junior ? offer.junior : {});
    const element = document.getElementById("shop-offer");
    if (element) {
      const yOffset = -100;
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const toggleShowMoreOffers = () => {
    setShowMoreOffers(!showMoreOffers);
  };

  useEffect(() => {
    if (resetDatePicker) {
      setResetDatePicker(false);
    }
  }, [resetDatePicker]);

  const handleIncrement = () => {
    if (quantity < maxQuantity) {
      setQuantity((prevQuantity) => prevQuantity + 1);
      setQuantityLimitMessage("");
    } else {
      setQuantityLimitMessage("No hay más unidades disponibles");
      setTimeout(() => {
        setQuantityLimitMessage("");
      }, 2000);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      setQuantityLimitMessage("");
    }
  };

  const handleDateChange = (dates) => {
    setDates({ from_date: dates.startDate, to_date: dates.endDate });

    if (dates.startDate && dates.endDate) {
      const start = new Date(dates.startDate);
      const end = new Date(dates.endDate);
      const timeDiff = end - start;
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
      setTotalDays(daysDiff);
    } else {
      setTotalDays(0);
    }
  };

  const handleImageClick = (imagePath) => {
    setModalImage(imagePath);
    setModalVisible(true);
  };

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const shareContent = (event) => {
    event.preventDefault(); // Evita cualquier comportamiento de recarga de la página
    const url = window.location.href;

    if (navigator.share) {
      navigator
        .share({ title: "Mira esta página", url: url })
        .then(() => {
          setModalMessage("Gracias por compartir!");
          setShowModal(true);
        })
        .catch((error) => {
          console.error("Error compartiendo:", error);
          fallbackCopyToClipboard(url);
        });
    } else {
      fallbackCopyToClipboard(url);
    }
  };

  const fallbackCopyToClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setModalMessage("URL copiada al portapapeles!");
        setShowModal(true);
      })
      .catch((err) => {
        console.error("Error al copiar la URL: ", err);
        setModalMessage("Error al copiar la URL");
        setShowModal(true);
      });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleZipChange = (content) => {
    // Cancela el temporizador anterior
    if (timerId) clearTimeout(timerId);

    // Crea un nuevo temporizador
    const newTimerId = setTimeout(() => {
      setZip(content);
    }, 1000); // 1 segundo de retraso

    // Almacena el nuevo identificador del temporizador
    setTimerId(newTimerId);
  };

  useEffect(() => {
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [timerId]);

  const handleFetchShippingInfo = async () => {
    if (!zip.trim()) {
      setError(new Error("Por favor, ingrese un código postal válido."));
      return;
    }

    try {
      const responseData = await fetchShippingInfo(
        selectedOffer?.id,
        zip,
        storePickup,
        storeDelivery,
        setError
      );
      setAmount(responseData.amount);
    } catch (error) {
      setError(error);
      console.error("Error en la solicitud de información de envío:", error);
    }
  };

  useEffect(() => {
    if (
      zip.trim() &&
      selectedOffer &&
      (storePickup !== null || storeDelivery !== null)
    ) {
      handleFetchShippingInfo();
    }
  }, [zip, storePickup, storeDelivery, selectedOffer]);

  useEffect(() => {
    if (selectedOptions[0]) {
      setStorePickup(selectedOptions[0][1]);
    }
    if (selectedOptions[1]) {
      setStoreDelivery(selectedOptions[1][1]);
    }
  }, [selectedOptions]);

  const tagsFilters = product.category?.tags_filters || [];
  const seoText = product.category?.seo_text || "";

  const getPeriodString = (minPeriod, periodType) => {
    if (minPeriod === null || periodType === null) {
      return "No hay disponible";
    }

    const periodTypes = {
      1: "hora",
      2: "día",
      3: "semana",
      4: "mes",
      5: "semestre",
      6: "año",
    };

    const period = periodTypes[periodType];
    return `${minPeriod} ${minPeriod > 1 ? period + "s" : period}`;
  };

  const [shippingSelected, setShippingSelected] = useState("");
  const [shippingOptions, setShippingOptions] = useState(false);

  const handleShippingOption = (option, shipping) => {
    // let newSelectedOptions = {...selectedShippingOptions};
    let updatedOptions = { ...selectedOptions };
    // Obtiene la clave del objeto shipping (asumiendo que siempre hay una única clave)
    const shippingKey = Object.keys(shipping)[0];

    if (updatedOptions.hasOwnProperty(shippingKey)) {
      // Comprueba si la posición 1 del array es igual a la que quieres comparar
      if (updatedOptions[shippingKey][1] === shipping[shippingKey][1]) {
        // Si es igual, solo borra el elemento existente
        delete updatedOptions[shippingKey];
      } else {
        // Si es diferente, borra el existente y añade el nuevo
        delete updatedOptions[shippingKey];
        updatedOptions[shippingKey] = shipping[shippingKey];
      }
    } else {
      // Si no existe la propiedad, simplemente añade el nuevo par clave-valor
      updatedOptions[shippingKey] = shipping[shippingKey];
    }

    if (shippingOptions)
      // Actualiza el estado de selectedOptions con el objeto modificado
      setSelectedOptions(updatedOptions);

    // if (newSelectedOptions.includes(option)) {
    //   const index = newSelectedOptions.indexOf(option);
    //   newSelectedOptions.splice(index, 1);
    // } else {
    //   if (newSelectedOptions.length < 2) {
    //     newSelectedOptions.push(option);
    //   } else {
    //     return;
    //   }
    // }

    // let newStorePickup = false;
    // let newStoreDelivery = false;

    // newSelectedOptions.forEach((opt) => {
    //   switch (opt) {
    //     case "Recogida en almacén":
    //       newStorePickup = true;
    //       break;
    //     case "Devolución en almacén":
    //       newStoreDelivery = true;
    //       break;
    //     case "Envío a domicilio":
    //       newStorePickup = false;
    //       break;
    //     case "Recogida en domicilio":
    //       newStoreDelivery = false;
    //       break;
    //     default:
    //       break;
    //   }
    // });

    // setSelectedShippingOptions(newSelectedOptions);
    // setStorePickup(newStorePickup);
    // setStoreDelivery(newStoreDelivery);

    // setShippingSelected(newSelectedOptions.join(", "));
    // if (newSelectedOptions.length === 2) {
    //   setShippingOptions(false);
    // }
  };

  const resetShippingOptions = () => {
    setSelectedShippingOptions([]);
    setStorePickup(false);
    setStoreDelivery(false);
    setShippingSelected("");
  };

  // const isOptionDisabled = (option) => {
  //   if (
  //     selectedShippingOptions.length === 2 &&
  //     !selectedShippingOptions.includes(option)
  //   ) {
  //     return true;
  //   }
  //   if (
  //     option === "Recogida en almacén" &&
  //     selectedShippingOptions.includes("Envío a domicilio")
  //   ) {
  //     return true;
  //   }
  //   if (
  //     option === "Devolución en almacén" &&
  //     selectedShippingOptions.includes("Recogida en domicilio")
  //   ) {
  //     return true;
  //   }
  //   if (
  //     option === "Envío a domicilio" &&
  //     selectedShippingOptions.includes("Recogida en almacén")
  //   ) {
  //     return true;
  //   }
  //   if (
  //     option === "Recogida en domicilio" &&
  //     selectedShippingOptions.includes("Devolución en almacén")
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  useEffect(() => {
    if (product?.products_offers_published?.length > 0) {
      const defaultOffer = product.products_offers_published[0];
      setSelectedOffer(defaultOffer);
      setSelectedZip(defaultOffer.junior ? defaultOffer.junior.zip : "");
      setSelectedTown(defaultOffer.junior ? defaultOffer.junior.town : "");
      setSelectedLocation(defaultOffer.junior ? defaultOffer.junior : {});
    }
  }, [product]);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchFavorites = async () => {
        try {
          const favoriteProducts = await fetchUserFavorites(token);
          setFavorites(favoriteProducts.map((product) => product.id));
          setFavoriteStatus(
            favoriteProducts.some((fav) => fav.id === product.id)
          );
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchFavorites();
    }
  }, [isAuthenticated, token, product.id, setError]);

  const handleToggleFavorite = async () => {
    if (loadingFavorite) return;

    setLoadingFavorite(true);

    try {
      await toggleFavoriteProduct(token, product.id, favoriteStatus);
      setFavoriteStatus(!favoriteStatus);
    } catch (error) {
      setError(error);
      console.error("Error managing favorite:", error);
    } finally {
      setLoadingFavorite(false);
    }
  };

  useEffect(() => {
    if (selectedOffer) {
      // if there's a selected offer load the holidays and update the related states
      const loadSelectedOfferData = async () => {
        try {
          await loadHolidays(selectedOffer.id);

          setMaxQuantity(parseInt(selectedOffer.quantity, 10));
          setMinPeriodString(
            getPeriodString(selectedOffer.min_period, selectedOffer.period_type)
          );

          setSelectedZip(selectedOffer.junior ? selectedOffer.junior.zip : "");
          setSelectedTown(
            selectedOffer.junior ? selectedOffer.junior.town : ""
          );
          setSelectedLocation(selectedOffer.junior ? selectedOffer.junior : {});

          const { no_delivery, only_delivery } = selectedOffer.junior || {};
          if (no_delivery && !only_delivery) {
            setSelectedShippingOptions([
              "Recogida en almacén",
              "Devolución en almacén",
            ]);
            setStorePickup(true);
            setStoreDelivery(true);
          } else if (!no_delivery && only_delivery) {
            setSelectedShippingOptions([
              "Envío a domicilio",
              "Recogida en domicilio",
            ]);
            setStorePickup(false);
            setStoreDelivery(false);
          } else {
            setSelectedShippingOptions([]);
            setStorePickup(false);
            setStoreDelivery(false);
          }
        } catch (error) {
          console.error("Error loading selected offer data:", error);
          setError(error);
        }
      };

      loadSelectedOfferData();
    }
  }, [selectedOffer, setError]);

  return (
    <React.Fragment>
      <section className="container w-full m-auto lg:pt-6 lg:flex">
        <aside
          style={{ scrollbarWidth: "none" }}
          className="lg:pb-44 lg:sticky top-24 left-0 w-full lg:w-4/12 h-fit lg:h-[90vh] px-0 lg:pl-9"
          ariaLabel="Sidebar"
        >
          {loading ? (
            <Skeleton width={40} />
          ) : product?.images ? (
            <ProductImages
              images={product.images}
              url_images={process.env.REACT_APP_IMAGES_BASE_URL}
              setThumbsSwiper={setThumbsSwiper}
              thumbsSwiper={thumbsSwiper}
              handleImageClick={handleImageClick}
            />
          ) : null}
        </aside>

        <Modal
          isVisible={modalVisible}
          imageSrc={modalImage}
          onClose={() => setModalVisible(false)}
        />

        <div className="w-full px-4 py-4 text-left lg:w-8/12 lg:px-9">
          <div className="flex items-center justify-between w-full mb-2">
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-2 pb-2">
                {isAuthenticated ? (
                  <div
                    className="mr-2 cursor-pointer"
                    onClick={handleToggleFavorite}
                  >
                    <img
                      src={favoriteStatus ? imageHeartFilled : imageHeart}
                      alt="heart icon"
                      className={`text-primary ${
                        loadingFavorite ? "opacity-50" : "opacity-100"
                      }`}
                    />
                  </div>
                ) : (
                  ""
                )}
                <ShareIcon className="w-4 h-4" />
                <p
                  className="transition-all duration-150 ease-in-out caption text-darkgray hover:text-dark cursor-pointer"
                  onClick={shareContent}
                >
                  Compartir
                </p>
                {showModal && (
                  <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div
                      className="bg-black bg-opacity-50 absolute inset-0"
                      onClick={closeModal}
                    ></div>
                    <div className="bg-white p-6 rounded-lg z-10 relative">
                      <button
                        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                        onClick={closeModal}
                      >
                        &times;
                      </button>
                      <p>{modalMessage}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            {product.products_offers_published &&
              product.products_offers_published.length > 0 && (
                <>
                  {product.products_offers_published[0].rating > 0 && (
                    <StarRating
                      rating={product.products_offers_published[0].rating}
                    />
                  )}
                  {product.products_offers_published[0].reviews > 0 && (
                    <span className="text-[12px] text-[#B8B8B8]">
                      {product.products_offers_published[0].reviews} reseñas
                    </span>
                  )}
                </>
              )}
          </div>
          <ProductDetails
            product={product}
            loading={loading}
            selectedOffer={selectedOffer}
            handleSelectOffer={handleSelectOffer}
            showMoreOffers={showMoreOffers}
            toggleShowMoreOffers={toggleShowMoreOffers}
            formatDisabledDates={formatDisabledDates}
            handleDateChange={handleDateChange}
            holidays={holidays}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            quantity={quantity}
            quantityLimitMessage={quantityLimitMessage}
            totalDays={totalDays}
            minPeriodString={minPeriodString}
            setShippingOptions={setShippingOptions}
            shippingOptions={shippingOptions}
            opciones={opciones}
            options={options}
            selectedOptions={selectedOptions}
            handleShippingOption={handleShippingOption}
            shippingSelected={shippingSelected}
            zip={zip}
            handleZipChange={handleZipChange}
            handleFetchShippingInfo={handleFetchShippingInfo}
            amount={amount}
            selectedZip={selectedZip}
            selectedTown={selectedTown}
            // isOptionDisabled={isOptionDisabled}
            selectedShippingOptions={selectedShippingOptions}
            resetShippingOptions={resetShippingOptions}
            modalClick={setIsModalOpen}
          />
          <div className="w-full">
            {loading ? (
              <Skeleton width={120} height={48} />
            ) : product?.products_offers_published?.length > 0 ? (
              <ShoppingCart
                sessionID={sessionID}
                dates={dates}
                quantity={quantity}
                offerId={selectedOffer?.id}
                zip={zip}
                storePickup={storePickup}
                storeDelivery={storeDelivery}
                selectedOptions={selectedOptions}
                selectedZip={selectedZip}
                addToCartActive={addToCartActive}
              />
            ) : (
              <button className="flex items-center justify-center w-full mb-2 text-white transition-all duration-150 ease-in-out rounded-full h-14 bg-primary button hover:bg-opacity-90">
                <p>No disponible</p>
              </button>
            )}
            {/* <a onClick={() => setIsModalOpen(true)}>
              {" "}
              <button className="flex items-center justify-center w-full font-bold transition-all duration-150 ease-in-out border-2 rounded-full h-14 caption border-primary text-dark hover:bg-white hover:text-primary">
                <p className="lowercase">
                  ¿Quieres chatear con el experto en este producto?
                </p>
                <Chat className="ml-4" />
              </button>
            </a> */}
          </div>
          {/*
          <div className="mt-12 accordion md:mt-4">
            <AccordionCustomIcon />
          </div>
          */}
        </div>
      </section>
      <ModalInfo isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

      <section
        id="description-full"
        className="w-full px-4 mt-9 lg:mt-32 md:px-12"
      >
        <TransparentTabs
          description={product.description_ext}
          location={selectedLocation}
        />
      </section>

      <BestSellerProducts slides={slides} />
      {/* <CategoryAndSeoSection tagsFilters={tagsFilters} seoText={seoText} /> */}
      <ProductSchema product={product} />
    </React.Fragment>
  );
};

export default PDP;

import React from "react";
import imageXunta from "../assets/images/xunta.webp";
import imageAnapat from "../assets/images/anapat.webp";
import imageIndustria from "../assets/images/xunta-galicia.png";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/images/footer", false, /\.(png|jpe?g|svg|webp)$/)
);

const press_images = importAll(
  require.context("../assets/images/Press", false, /\.(png|jpe?g|svg|webp)$/)
);

const footer_social_media = [
  {
    image: images["facebook.png"],
    link: "https://www.facebook.com/elalquilador",
  },
  {
    image: images["instagram.png"],
    link: "https://www.instagram.com/elalquiladorweb/",
  },
  {
    image: images["spotify.png"],
    link: "https://open.spotify.com/user/1151071580",
  },
  {
    image: images["twiter.png"],
    link: "https://twitter.com/elalquiladorweb",
  },
  {
    image: images["youtube.png"],
    link: "https://www.youtube.com/channel/UCFQV-dKMNEM-zCfSZpu86zg/featured",
  },
  {
    image: images["linkedin.png"],
    link: "https://www.linkedin.com/company/elalquilador/",
  },
];

const footer_medios = [
  {
    image: press_images["el-mundo.webp"],
    link: "https://www.elmundo.es/economia/dinero-inversion/2024/04/05/661022f3e85ece573d8b45ab.html",
  },
  {
    image: press_images["elperiodico.webp"],
    link: "https://www.elperiodico.com/es/sociedad/20240213/elalquilador-com-solucion-obtener-herramienta-98136078",
  },
  {
    image: press_images["la-razon.webp"],
    link: "https://www.larazon.es/economia/alquilar-andamio-tabla-surf-esta-plataforma-donde-podra-hacerlo_202312256589295ad7b0c30001a829d2.html",
  },
];

const footer_logo = [
  {
    image: images["main_logo_light.png"],
  },
];

function Footer() {
  return (
    <footer className="bg-primary">
      <div className="container grid w-full grid-cols-1 gap-4 px-4 py-12 m-auto space-y-12 lg:grid-cols-5 lg:space-y-0">
        <div className="flex flex-col justify-self-start">
          {footer_logo.map((item, index) => (
            <img key={index} src={item.image} className="mb-4 h-7" alt="Logo" />
          ))}
          <ul className="space-y-3 font-semibold body text-start">
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/quienes-somos">¿Quiénes somos?</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/como-funcionamos">Cómo funcionamos</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/motivos-para-alquilar">Motivos para alquilar</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/blog">elalquilablog</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/trabaja-con-nosotros">Trabaja con nosotros</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/sostenibilidad">Sostenibilidad</a>
            </li>
          </ul>
        </div>
        <div className="text-start ">
          <p className="mb-6 text-white h4">¿necesitas ayuda?</p>
          <ul className="space-y-3 font-semibold body">
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/proveedores">¿Quieres ser proveedor?</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/escribe-a-nuestro-experto">Escribe a nuestro experto</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/como-funcionamos#preguntas-frecuentes">
                Preguntas frecuentes
              </a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/videotutoriales">Videotutoriales</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/guias">Guías de nuestros expertos</a>
            </li>
          </ul>
        </div>
        <div className="text-start">
          <p className="mb-6 text-white h4">legal</p>
          <ul className="space-y-3 font-semibold body">
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/aviso-legal">Aviso legal</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/politica-de-privacidad">Política de privacidad</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/politica-cookies">Cookies</a>
            </li>
            <li className="duration-300 ease-in-out text-dark hover:text-white">
              <a href="/condiciones-de-uso">Condiciones de uso</a>
            </li>
          </ul>
        </div>
        <div className="space-y-6 text-start">
          <div>
            <p className="mb-6 text-white h4">
              <a href="/quienes-somos#section_talk">hablan de nosotros</a>
            </p>
            <div className="flex justify-start space-x-6">
              {footer_medios.map((item, index) => (
                <a key={index} href={item.link ? item.link : "#"}>
                  <img
                    className="w-28 h-4.8 rounded-xl"
                    src={item.image}
                    alt=""
                  />
                </a>
              ))}
            </div>
          </div>
          <div>
            <p className="mb-6 text-white h4">síguenos</p>
            <div className="flex justify-start space-x-5">
              {footer_social_media.map((item, index) => (
                <a
                  key={index}
                  href={item.link ? item.link : "#"}
                  target="_blank"
                >
                  <img
                    className="h-6 duration-300 ease-in-out opacity-100 hover:opacity-60"
                    src={item.image}
                    alt=""
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="lg:pl-12">
          <p className="mb-6 text-white h4 text-start">nos avalan</p>
          <div class="flex w-fit items-center gap-2 rounded-xl bg-white p-4">
            <a
              href="https://www.xunta.gal/portada"
              className="hidden"
              target="_blank"
            >
              <img
                className="w-28 h-4.8"
                src={imageXunta}
                alt="Xunta de Galicia"
              />
            </a>
            <a href="https://anapat.es/" target="_blank">
              <img className="w-32 h-auto" src={imageAnapat} alt="Anapat" />
            </a>
          </div>
          <div class="mt-4 flex w-full items-center gap-2 rounded-xl bg-white p-4">
            <a href="/subvenciones" target="_blank">
              <img
                className="w-full h-auto"
                src={imageIndustria}
                alt="Xunta de Galicia"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full py-4 text-xs font-bold text-center text-white border-t border-t-white border-opacity-20">
        2024 © elaquilador.com. Todos los derechos reservados.
      </div>
    </footer>
  );
}

export default Footer;
